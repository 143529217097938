.blog--page  {
  background: #E8E8E1;
  &--title {
    padding-top: 96px;
    padding-bottom: 96px;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #20150F;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 992px) {
      padding-top: 120px;
      padding-bottom: 120px;
      font-size: 56px;
      line-height: 100%;
    }
  }
  &--content {
    background: #f1f1e8;
    padding: 55px 0;
    @media (min-width: 992px) {
      padding: 80px 0;
    }
    .container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 16px;
      margin-top: 56px;
      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
      }
      @media (min-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
  }
  &--item {
    border: 1px solid #D6D6CE;
    border-radius: 16px;
    padding-bottom: 24px;
    position: relative;
    &:hover {
      background: #FFFFFF;
      cursor: pointer;
      .link {
        display: flex;
      }
    }
    .images {
      border-radius: 16px 16px 0px 0px;
      img {
        height: 240px;
        border-radius: 16px 16px 0px 0px;
        width: 100%;
        object-fit: cover;
      }
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #20150F;
      letter-spacing: 0.01em;
      margin-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .date {
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #7D7D76;
      letter-spacing: 0.01em;
      margin-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .link {
      background: #FFFFFF;
      border: 1px solid #D6D6CE;
      border-radius: 8px;
      position: absolute;
      bottom: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      display: none;
    }
  }
}
.blog--item {
  background: #F1F1E8;
  padding-bottom: 30px;

  .hero-block {
    position: relative;
    margin-bottom: 200px;
    max-width: 1250px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    //padding: 0 16px;
    img {
      height: 360px;
      object-fit: cover;
      @media (min-width: 600px) {
        height: auto;
      }
    }
    &--text {
      background: #FFFFFF;
      border-radius: 32px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,50%);
      padding: 24px;
      max-width: 650px;
      width: 90%;
      @media (min-width: 600px) {
        height: auto;
        width: 100%;
      }
      @media (min-width: 992px) {
        padding: 48px;
      }
      h1 {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.005em;
        color: #20150F;

      }
      .date {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #7D7D76;
        text-align: center;
        letter-spacing: 0.005em;
        margin-top: 32px;
        @media (min-width: 992px) {
          margin-top: 48px;
        }
      }
    }
  }
  .container {
    max-width: 825px;
  }
  p,span,li,ol {
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.005em;
    color: #20150F;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.005em;
    color: #20150F;
    margin-bottom: 24px;
    margin-top: 56px;
  }
  h3 {
    margin-bottom: 40px;
  }
  h1,h2,h3,h4,h5,h6 {
    letter-spacing: 0.005em;
    color: #20150F;
  }
  a {
    font-weight: 700;
    color: #20150F;
  }
}
