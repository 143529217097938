.catalog-page {
  background: #F1F1E8;
  padding-bottom: 96px;
  @media (min-width: 992px) {
    padding-bottom: 128px;
  }
  .catalog {
    padding-top: 80px;
    h1 {
      @media (min-width: 992px) {
       text-align: left;
      }
    }
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 16px;
      margin-top: 56px;
      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
    &--item {
      position: relative;
      min-height: 330px;
      //min-width: 345px;
      //max-width: 345px;
      width: 100%;
      justify-self: center;
      padding: 32px;
      .images {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 63.54%, #000000 100%);
          opacity: 0.8;
        }
      }
      .description {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        position: relative;
        max-width: 165px;
        .counter {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #FFFFFF;
          opacity: 0.89;
          margin-bottom: 12px;
        }
        .name {
          font-weight: 700;
          font-size: 24px;
          line-height: 140%;
          color: #FFFFFF;
        }
      }
    }
  }

  .all-catalog {
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      //grid-column-gap: 24px;
      grid-row-gap: 24px;
      @media (min-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
    &--item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 32px;
      @media (min-width: 700px) {
        flex-direction: column;
      }
      .images {
        height: 108px;
        @media (min-width: 700px) {
          height: 330px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .description {
        //margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
        @media (min-width: 700px) {
          margin-left: 0px;
          align-items: center;
          margin-top: 24px;
        }
        .category {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #20150F;
          //padding: 0 34px;
        }
        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #20150F;
          //padding: 0 34px;
        }
        .link {
          background: #27B539;
          border-radius: 12px;
          min-width: 145px;
          max-width: 145px;
          padding: 16px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #FFFFFF;
          margin-top: 16px;
        }
      }
    }
  }
}


.make-sets--page {
  padding-bottom: 96px;
  background: #F1F1E8;
  @media (min-width: 992px) {
    padding-bottom: 128px;
  }
  .make-sets {
    padding-top: 80px;
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 16px;
      margin-top: 56px;
      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
      }
      @media (min-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
    &--item {
      position: relative;
      min-height: 160px;
      //min-width: 345px;
      //max-width: 345px;
      width: 100%;
      justify-self: center;
      padding: 32px;
      border-radius: 8px;
      @media (min-width: 992px) {
        min-height: 246px;
      }
      .images {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 63.54%, #000000 100%);
          opacity: 0.8;
          border-radius: 8px;
        }
      }
      .description {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        position: relative;
        .counter {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #FFFFFF;
          opacity: 0.89;
          margin-bottom: 12px;
        }
        .name {
          font-weight: 700;
          font-size: 24px;
          line-height: 140%;
          color: #FFFFFF;
        }
      }
    }
  }

  .all-catalog {
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      //grid-column-gap: 24px;
      grid-row-gap: 24px;
      @media (min-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
    &--item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 32px;
      @media (min-width: 700px) {
        flex-direction: column;
      }
      .images {
        height: 108px;
        @media (min-width: 700px) {
          height: 330px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .description {
        //margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
        @media (min-width: 700px) {
          margin-left: 0px;
          align-items: center;
          margin-top: 24px;
        }
        .category {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #20150F;
          //padding: 0 34px;
        }
        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #20150F;
          //padding: 0 34px;
        }
        .link {
          background: #27B539;
          border-radius: 12px;
          min-width: 145px;
          max-width: 145px;
          padding: 16px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #FFFFFF;
          margin-top: 16px;
        }
      }
    }
  }
}


.category--page {
  background: #F1F1E8;
  padding-bottom: 96px;
  @media (min-width: 992px) {
    padding-bottom: 128px;
  }
  .category {
    padding-top: 80px;
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      //grid-column-gap: 24px;
      grid-row-gap: 24px;
      margin-top: 56px;
      @media (min-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
    }
    &--item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 32px;
      @media (min-width: 700px) {
        flex-direction: column;
      }
      .images {
        height: 108px;
        @media (min-width: 700px) {
          height: 330px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .description {
        //margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
        @media (min-width: 700px) {
          margin-left: 0px;
          align-items: center;
          margin-top: 24px;
        }
        .category {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #20150F;
          padding-top: 0;
          //padding: 0 34px;
        }
        .name {
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #20150F;
          //padding: 0 34px;
        }
        .link {
          background: #27B539;
          border-radius: 12px;
          min-width: 145px;
          max-width: 145px;
          padding: 16px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #FFFFFF;
          margin-top: 16px;
        }
      }
    }
  }
}
