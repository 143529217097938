.privacy-policy--page, .terms--page {
  background: #F1F1E8;
  padding: 96px 0;
  @media (min-width: 992px) {
    padding: 120px 0;
  }
  .container {
    max-width: 825px;
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 125%;
    letter-spacing: 0.005em;
    color: #20150F;
    margin-bottom: 55px;
    @media (min-width: 992px) {
      font-size: 40px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 175%;
    letter-spacing: 0.005em;
    color: #20150F;
    margin-top: 55px;
    margin-bottom: 24px;
  }
  p,span,a,li {
    font-weight: 400;
    font-size: 18px;
    line-height: 175%;
    color: #20150F;
  }
}
