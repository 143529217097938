.footer {
  padding: 80px 0;
  position: relative;
  padding-bottom: 160px;
  background: #E8E8E1;
  @media (min-width: 992px) {
    padding: 80px 0 80px;
  }
  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 750px) {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  &--logo {
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #20150F;
    }
    svg {
      margin-right: 12px;
    }
  }

  &--left {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 750px) {
      align-items: flex-start;
      width: calc(50% - 15px);
    }
    .privacy-policy {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      @media (min-width: 750px) {
        position: static;
        transform: translateX(0%);
        flex-direction: row;
        margin-top: 82px;
      }
      &__link {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.01em;
        color: #7D7D76;
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
        @media (min-width: 750px) {
          margin-top: 0;
          margin-left: 16px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    @media (min-width: 750px) {
      flex-direction: row;
      padding-bottom: 0px;
      width: calc(50% - 15px);
      align-items: flex-start;
      justify-content: space-around;
    }
  }

  &--block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    @media (min-width: 750px) {
      margin-top: 0px;
      width: 50%;
      align-items: flex-start;
    }
    h5 {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #7D7D76;
      margin: 0;
      margin-bottom: 8px;
    }
    &_link {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #171414;
      margin-top: 16px;
      @media (min-width: 750px) {
        margin-top: 24px;
      }
      &:first-child {
        margin-top: 0;
      }
      svg {
        margin-right: 12px;
      }
    }
  }
}
