.partnership--page {
  background: #F1F1E8;
  padding-bottom: 96px;
  @media (min-width: 992px) {
    padding-bottom: 128px;
  }
  .partnership {
    position: relative;
    z-index: 100;
    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      $vw-viewport: 1440;
      $mobile-viewport: 320;
      @function calcFluidFontSize($f-min, $f-max) {
        $k: calc(($f-max - $f-min) / ($vw-viewport - $mobile-viewport));
        $b: $f-min - $k * $mobile-viewport;
        $b: $b * 1px;
        @return calc( #{$k} * 100vw + #{$b} );
      }
      //height: calcFluidFontSize(784, 660);
      height: calcFluidFontSize(500, 660);
      //object-fit: cover;
    }
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 650px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .main--description {
      margin-top: 16px;
    }
    .link {
      margin-top: 24px;
      background: #27B539;
      border-radius: 12px;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.005em;
      color: #FFFFFF;
    }
  }
  .video {
    padding-top: 150px;
    @media (min-width: 1200px) {
      padding-top: 200px;
    }
    .container {
      max-width: 1036px;
    }
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &--item {
      width: 100%;
      margin-top: 56px;
      $vw-viewport: 1440;
      $mobile-viewport: 320;
      @function calcFluidFontSize($f-min, $f-max) {
        $k: calc(($f-max - $f-min) / ($vw-viewport - $mobile-viewport));
        $b: $f-min - $k * $mobile-viewport;
        $b: $b * 1px;
        @return calc( #{$k} * 100vw + #{$b} );
      }
      height: calcFluidFontSize(200, 580) !important;
      &:first-child {
        margin-top: 0;
      }
      @media (min-width: 992px) {
        margin-top: 80px;
      }
    }
  }
  .write-in-messengers {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    @media (min-width: 750px) {
      margin-top: 48px;
    }
    p {
      text-align: left;
    }
    &--social {
      display: flex;
      margin-top: 16px;
    }
    &--item {
      display: flex;
      align-items: center;
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
