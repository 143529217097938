.header  {
  display: flex;
  padding: 16px 0;
  background: #F1F1E8;

  .container {
    z-index: 10;
  }
  &--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--logo {
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #20150F;
    }
    svg {
      margin-right: 12px;
    }
  }

  &--left {

  }

  &--center, .links {
    display: none;
    @media (min-width: 992px) {
      display: flex;
    }

    &--item {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #20150F;
      margin-left: 32px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--right, .social {
    display: none;
    @media (min-width: 992px) {
      display: flex;
    }
    &--item {
      display: flex;
      align-items: center;
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--modal {
    @media (min-width: 992px) {
      display: none;
    }
  }
}


.hamburger {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #F1F1E8;
  display: none;
  transition: all 0.3s;
  z-index: 9999;
  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  &--close {
    position: absolute;
    top: 20px;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  &--logo {
    display: flex;
    align-items: center;
    margin-top: 60px;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #20150F;
    }
    svg {
      margin-right: 12px;
    }
  }
  &--links,.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--item {
      font-weight: 400;
      font-size: 24px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #20150F;
      margin-top: 48px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--social, .social {
    display: flex;
    align-items: center;
    justify-content: center;
    &--item {
      margin-left: 16px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.active {
    display: block;
    left: 0;
    transition: all 0.3s;
  }
}

.partnership {
  //position: relative;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    $vw-viewport: 1440;
    $mobile-viewport: 320;
    @function calcFluidFontSize($f-min, $f-max) {
      $k: calc(($f-max - $f-min) / ($vw-viewport - $mobile-viewport));
      $b: $f-min - $k * $mobile-viewport;
      $b: $b * 1px;
      @return calc(#{$k} * 100vw + #{$b});
    }
    height: calcFluidFontSize(500, 660);
    @media (min-width: 425px) {
      display: none;
    }
    @media (min-width: 992px) {
      display: block;
    }
    @media (min-width: 1440px) {
      height: 660px;
    }
    //object-fit: cover;
  }
}
