.discount--page {
  .hero {
    position: relative;
    padding-top: 40px;
    background: #E8E8E1;
    @media (min-width: 992px) {
     height: 565px;
    }
    .container {
      padding-right: 0;
    }
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &--left {
      padding-right: 16px;
      @media (min-width: 992px) {
        width: calc(50% - 20px);
      }
      .main--title {
        text-align: left;
        margin-bottom: 24px;
      }
      .main--description {
        text-align: left;
      }
      .hero-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media (min-width: 992px) {
          flex-direction: row;
          align-items: center;
          margin-top: 32px;
        }
        .link {
          background: #27B539;
          border-radius: 12px;
          //min-width: 145px;
          //max-width: 145px;
          padding: 16px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.005em;
          color: #FFFFFF;
          margin-top: 24px;
          border: none;
          @media (min-width: 992px) {
            margin-top: 0px;
            flex-shrink: 0;
          }
        }
        .button {
          display: flex;
          align-items: center;
          border: 1px solid #27B539;
          border-radius: 12px;
          padding: 12px 24px;
          margin-top: 12px;
          margin-bottom: 16px;
          @media (min-width: 992px) {
            margin-top: 0px;
            margin-bottom: 0px;
            margin-right: 16px;
            margin-left: 16px;
            flex-shrink: 0;
          }
          svg {
            margin-right: 12px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.005em;
            color: #27B539;
          }
        }
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: 0.005em;
          color: #7D7D76;
          max-width: 120px;
        }
      }
    }

    &--right {
      margin-top: 32px;
      align-self: flex-end;
      @media (min-width: 992px) {
        width: calc(50% - 20px);
      }
      img {
        height: 346px;
        object-fit: contain;
        @media (min-width: 992px) {
          display: none;
        }
      }
    }
    &__bg {
      display: none;
      @media (min-width: 992px) {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        //width: calc(50% - 20px);
        max-height: 565px;
        object-fit: contain;
        width: calc(50% - 20px);
      }
      @media (min-width: 1200px) {
        width: auto;
      }
    }
  }
  .why-program {
    background: #f1f1e8;
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 24px;
      margin-top: 56px;
      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 0px;
        grid-column-gap: 24px;
      }
    }

    &--item {
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #20150F;
        margin-top: 32px;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        letter-spacing: 0.02em;
        color: #20150F;
        margin-top: 16px;
      }
    }
  }
  .three-steps {
    background: #f1f1e8;
    &--content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 24px;
      margin-top: 56px;
      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
      }
      @media (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 0px;
        grid-column-gap: 24px;
      }
    }

    &--item {
      background: #E8E8E1;
      border-radius: 24px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #20150F;
        margin-top: 32px;
        text-align: center;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        letter-spacing: 0.02em;
        color: #20150F;
        margin-top: 16px;
        text-align: center;
      }
      .link {
        background: #27B539;
        border-radius: 12px;
        //min-width: 145px;
        //max-width: 145px;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        margin-top: 0px;
        @media (min-width: 992px) {
          margin-top: 32px;
          flex-shrink: 0;
        }
      }
    }
  }

  .write-in-messengers {
    background: #f1f1e8;
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &--social {
      display: flex;
      margin-top: 32px;
    }
    &--item {
      display: flex;
      align-items: center;
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.discount--popup {
  //padding: 0 16px;
  padding: 16px 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background: rgba(0, 0, 0, 0.8);
  .container {
    //height: 90vh;
    //overflow: auto;
    //border-radius: 32px;
  }
  &--content {
    //background: #FFFFFF;
    //border-radius: 32px;
    display: flex;
    flex-direction: column;
    //padding: 32px;
    max-width: 625px;
    width: 100%;
    height: 90vh;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    @media (min-height: 670px) {
      height: auto;
    }
    @media (min-width: 992px) {
      padding: 56px;
      height: auto;
    }
  }
  iframe {
    width: 100%;
    max-height: 465px;
  }
  button {
    padding: 16px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.005em;
    color: #FFFFFF;
    max-width: 200px;
    width: 100%;
    border: none;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 992px) {
      margin-top: 32px;

    }
  }
  &.active  {
    display: flex;
  }
}
