.container {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

* {
  font-family: 'Poppins',sans-serif;
}
h1 {
  font-family: 'Lora',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #20150F;
  @media (min-width: 992px) {
    font-size: 56px;
    line-height: 125%;
  }
}

.main--title {
  font-family: 'Lora',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #20150F;
  margin-bottom: 56px;
  @media (min-width: 992px) {
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 80px;
  }
}

.main--description {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #20150F;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 175%;

  }
}

.accordion {
  background: #F1F1E8;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: #F1F1E8;
}

.panel {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.p_top_bot {
  padding: 48px 0px;
  @media (min-width: 992px) {
    padding: 72px 0px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  &--content {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    .pagination--item {
      border-radius: 8px;
      padding: 8px 12px;
      &:nth-child(3){
        display: none;
      }
      &:nth-child(4){
        display: none;
      }
    }
    @media (min-width: 992px) {
      margin-left: 32px;
      margin-right: 32px;
      .pagination--item {
        &:nth-child(3){
          display: block;
        }
        &:nth-child(4){
          display: block;
        }
      }
    }
  }
  &--item {
    margin-left: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #20150F;
    &.active {
      background: #27B539;
      color: #FFFFFF;
    }
    &:hover {
      background: #27B539;
      color: #FFFFFF;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}


.breadcrumbs {
  &--item {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #27B539;
    }
  }
}
button {
  &:hover {
    cursor: pointer;
    border: none;
  }
}
