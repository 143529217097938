.main-page {
  background: #F1F1E8;

  .hero {
    padding-bottom: 0;
    &--title {
      font-family: 'Lora',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.005em;
      color: #20150F;
      max-width: 740px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 992px) {
        font-size: 56px;
        line-height: 125%;
      }
    }

    &--description {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      text-align: center;
      letter-spacing: 0.005em;
      color: #20150F;
      max-width: 600px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 16px;
      @media (min-width: 992px) {
        font-size: 18px;
        line-height: 175%;
        margin-top: 25px;
      }
    }

    &--link {
      max-width: 215px;
      width: 100%;
      padding: 14px 24px;
      background: #27B539;
      border: 2px solid #FFFFFF;
      border-radius: 12px;
      transform: translateY(38px);
      text-align: center;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: #FFFFFF;
      }
    }

    &--images {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  .works-for-me {
    //margin-top: 96px;
    @media (min-width: 992px) {
      //margin-top: 144px;
    }
    &--content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(290px, 290px));
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      justify-content: center;
      margin-top: 56px;
      @media (min-width: 992px) {
        margin-top: 80px;
      }
    }
    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 56px;
        height: 56px;
        @media (min-width: 992px) {
          width: 80px;
          height: 80px;
        }
      }
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #20150F;
        margin-top: 16px;
        @media (min-width: 992px) {
          margin-top: 24px;
        }
      }
      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #20150F;
        max-width: 225px;
        margin-top: 16px;

      }
    }
  }

  .recommended {
    &--content {
      .swiper-wrapper {
        @media (min-width: 992px) {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 24px;
        }
      }
      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 32px;
        .images {

        }

        .description {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .name {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #20150F;
            padding: 0 34px;
          }
          .link {
            background: #27B539;
            border-radius: 12px;
            min-width: 145px;
            max-width: 145px;
            padding: 16px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.005em;
            color: #FFFFFF;
            margin-top: 24px;
          }
        }
      }

      .swiper-pagination {
        position: static;
        margin-top: 16px;
        @media (min-width: 992px) {
          display: none;
        }
      }
    }
  }

  .asked-questions {
    .container {
      max-width: 1036px;
    }
    &--content {
      padding-bottom: 24px;
    }
    &--item {
      border-bottom: 1px solid #D6D6CE;
      padding-bottom: 24px;
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
      .accordion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        padding-bottom: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #20150F;
        .icon-open {
          display: block;
        }
        .icon-close {
          display: none;
        }
        &.active {
          margin-bottom: 16px;
          .icon-open {
            display: none;
          }
          .icon-close {
            display: block;
          }
        }
      }
      .panel {
        font-family: 'Poppins';
        background: #F1F1E8;
        padding-left: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #20150F;
        //margin-top: 16px;
        //padding-top: 16px;
      }
    }
  }
}


.main--popup {
  //padding: 0 16px;
  padding: 16px 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background: rgba(0, 0, 0, 0.6);
  &.active{
    display: flex;
  }
  .container {
    //height: 90vh;
    //overflow: auto;
    //border-radius: 32px;
  }
  &--content {
    background: #FFFFFF;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    padding: 32px;
    max-width: 625px;
    width: 100%;
    height: 90vh;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    @media (min-height: 670px) {
      height: auto;
    }
    @media (min-width: 992px) {
      padding: 56px;
      height: auto;
    }
  }

  &--title {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 32px;
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 140%;
    }
    span {
      color: rgba(39, 181, 57, 1);
    }
  }

  .subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 12px;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 140%;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #000000;
    margin-bottom: 32px;
    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 155%;
    }
    a {
      color: rgba(9, 82, 192, 1);
    }
  }

  button {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #27B539;
    border-radius: 12px;
    background: #FFFFFF;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.005em;
      color: #27B539;
      margin-left: 12px;

    }
  }
}

.help--popup {
  padding: 16px 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background: rgba(0, 0, 0, 0.6);
  &.active {
    display: flex;
  }
  &--closed {
    position: absolute;
    top: 25px;
    right: 25px;
    &:hover {
      cursor: pointer;
    }
  }
  &--content {
    background: #FFFFFF;
    max-width: 625px;
    border-radius: 32px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 90px;
    padding-bottom: 42px;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
      color: #000000;
      margin-bottom: 32px;
    }
    .write-in-messengers--social {
      display: flex;
      align-items: center;
      justify-content: center;
      .write-in-messengers--item {
        margin-right: 16px;
      }
    }
  }
}
