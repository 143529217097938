.product--page {
  background: #F1F1E8;
  padding-bottom: 96px;
  @media (min-width: 992px) {
    padding-bottom: 128px;
  }
  .product {
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 45px;
      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &--left {
      @media (min-width: 992px) {
       width: calc(50% - 32px);
      }
      img {
        max-width: 343px;
        width: 100%;
        height: 343px;
        object-fit: cover;
        @media (min-width: 992px) {
          height: 612px;
          max-width: none;
        }
      }
    }

    &--right {
      margin-top: 56px;
      @media (min-width: 992px) {
        width: calc(50% - 32px);
      }
      .category {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #898989;
        @media (min-width: 992px) {
          font-size: 16px;
          line-height: 140%;
        }
      }
      .name {
        font-family: 'Lora',sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        color: #20150F;
        margin-top: 16px;
        @media (min-width: 992px) {
          font-size: 32px;
          line-height: 140%;
        }
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        color: #20150F;
        margin-top: 24px;
        @media (min-width: 992px) {
          margin-top: 32px;
          font-size: 16px;
          line-height: 155%;
        }
      }
      .link {
        background: #27B539;
        border-radius: 12px;
        max-width: 165px;
        width: 100%;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        margin-top: 16px;
        @media (min-width: 992px) {
          margin-top: 32px;
        }
      }
      .content {
        margin-top: 56px;
        &--item {
          border-bottom: 1px solid #D6D6CE;
          padding-bottom: 16px;
          //margin-top: 12px;
          &:first-child {
            margin-top: 0;
          }
          .accordion {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 0;
            padding-bottom: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.01em;
            color: #20150F;
            @media (min-width: 992px) {
              font-size: 18px;
              line-height: 100%;
            }
            &.active {
              margin-bottom: 16px;
              svg {
                transform: rotate(180deg);
              }
            }
          }
          .panel {
            font-family: 'Poppins';
            background: #F1F1E8;
            padding-left: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            color: #20150F;
            ul {
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
}
