.price-list--page {
  background: #F1F1E8;
  padding-bottom: 96px;
  @media (min-width: 992px) {
    padding-bottom: 128px;
  }
  .price-list {
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 750px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    &--left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (min-width: 750px) {
        width: calc(50% - 30px);
        flex-direction: column;
      }
      h1 {
        text-align: left;
        font-size: 32px;
        line-height: 125%;
      }
      .write-in-messengers {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;
        @media (min-width: 750px) {
          margin-top: 48px;
        }
        p {
          text-align: left;
        }
        &--social {
          display: flex;
          margin-top: 16px;
        }
        &--item {
          display: flex;
          align-items: center;
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    &--right {
      margin-top: 56px;
      @media (min-width: 750px) {
        width: calc(50% - 30px);
        margin-top: 0px;
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--item {
        background: #E8E8E1;
        border-radius: 24px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        padding: 16px;
        width: 100%;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 155%;
          letter-spacing: 0.02em;
          color: #20150F;
        }
        img {
          margin-right: 16px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .category {
    .main--title {
      text-align: left;
      margin-bottom: 32px;
    }
    &--content {
      .tag {

      }
      .nav-top {
        display: none;
        padding-bottom: 16px;
        border-bottom: 1px solid #D6D6CE;
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 100%;
          color: #7D7D76;
        }
        @media (min-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 46px;
        }
        &--left {
          width: calc(50% - 15px);
        }
        &--right {
          width: calc(50% - 15px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            width: calc(50% - 16px);
            text-align: right;
          }
        }
      }
      .category--list {
        flex-direction: column;
        display: flex;
        max-width: 450px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 56px;
        @media (min-width: 992px) {
          max-width: none;
        }
      }
      .category--item {
        margin-top: 32px;
        @media (min-width: 992px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
        }
        &:first-child {
          margin-top: 0;
        }
        &--left {
          display: flex;
          @media (min-width: 992px) {
            width: calc(50% - 15px);
          }
          .images {
            width: 110px;
            height: 110px;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .description {
            margin-left: 16px;
            @media (min-width: 992px) {
             display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
          .name {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: #000000;
            margin-bottom: 16px;
          }
          .text {
            display: none;
            @media (min-width: 992px) {
              display: block;
              font-weight: 400;
              font-size: 16px;
              line-height: 100%;
              color: #000000;
            }
          }

        }
        .prices {
          display: flex;
          align-items: center;
          @media (min-width: 992px) {
            display: none;
          }
          &-item {
            &:first-child {
              margin-right: 16px;
              p {
                font-weight: 400;
                font-size: 18px;
                line-height: 100%;
                color: #0B0A0A;
              }
            }
            &:last-child {
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                text-decoration-line: line-through;
                color: #7D7D76;
                @media (min-width: 992px) {
                  font-size: 18px;
                }
              }
            }
          }
        }
        &--right {
          @media (min-width: 992px) {
            display: flex;
            width: calc(50% - 15px);
          }
          .prices {
            display: none;
            @media (min-width: 992px) {
              display: flex;
             width: 100%;
            }
            &-item {
              width: calc(50% - 15px);
              display: flex;
              justify-content: right;
            }
          }
        }
      }
    }
  }
}


.tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &--item {
    margin-bottom: 8px;
    margin-right: 8px;
    position: relative;
    padding: 12px 24px;
    border-radius: 59px;

    border: 1px solid #D6D6CE;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: #20150F;
    }
    &.active {
      background: #27B539;
      padding-left: 58px;
      span {
        color: #FFFFFF;
      }
      &:before {
        content: url('../img/price-list/u_check.svg');
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }
    }
  }
}
